import React,{ useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import axios from 'axios';
import useSWR from 'swr'
import Spinner from "./components/Spinner";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Swiper from "swiper";
import './../sass/style.scss';
import AppContext from './context';
import Modal from "./components/Modal";

const url = window.location.pathname;

const fetcher = url => axios.get(url).then(res => res.data)

function Error404() {
    let slug = '?lang=';
    const lang = url.split('/')[1];
    if(lang === "en" || lang === "tr"){
        slug += lang;
    }else{
        slug = "";
    }
    const { data, error } = useSWR('/api/'+slug, fetcher)
    const [contextData, setContextData] = useState(null);
    useEffect(() => {

    }, [data])

    return data ? (
        <AppContext.Provider value={{ contextData, setContextData }}>
            <Header data={data.header} inPage={true} errorPage={true} />
            <div className="container page-404" style={{
                padding:'30px 0'
            }}>
             <div className={'row'} style={{
                 alignItems: 'center',
                 justifyContent: 'center',
                 marginBottom: '50px',
                 marginTop: '50px',
             }}>
                 <div className={'col-sm-5 order-1 order-sm-0'}>
                     <h1 className={"px-3 px-sm-0"} style={{
                         paddingRight: '30px'
                     }}>
                         {translations.sayfa_bulunamadi ?? 'Sayfa Bulunamadı'}
                     </h1>
                     <p className={"px-3 px-sm-0"}>
                         {translations.sayfa_bulunmadi_text ?? 'Üzgünüz ama aradığınız sayfa bulunamadı. İligli sayfa kaldırılmış yada değişmiş olabilir. Aşağıdaki arama bölümünden başka bir sayfayı bulabilir yada Ana Sayfa butonu yardımı ile ana sayfamıza gidebilirsiniz.'}
                     </p>
                     <div className={"d-flex flex-column flex-sm-row align-items-center gap-3"}>
                         <div className={"d-flex"}>
                             <input type="text" placeholder={translations.arama_yap ?? 'Arama Yap'} className={"form-control search-input"}/>
                             <button className={"btn-search"}>
                                 {translations.ara ?? 'Ara'}
                             </button>
                         </div>
                         <div>
                             <a href={lang === "en" ? '/en' : '/'} className={"btn-home"}>
                                 {translations.anasayfaya_git ?? 'ANA SAYFAYA GİT'}
                             </a>
                         </div>
                     </div>
                     <div className={"mt-5 mt-sm-3 d-flex flex-column flex-sm-row align-items-center justify-content-start gap-4"}>
                         <div className={"d-flex align-items-center"}>
                             <a href="tel:02123240074">
                                 <div className={"phone d-flex align-items-center gap-3 "}>
                                     <svg xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em"
                                          viewBox="0 0 24 24">
                                         <path fill="currentColor"
                                               d="m16.556 12.906l-.455.453s-1.083 1.076-4.038-1.862s-1.872-4.014-1.872-4.014l.286-.286c.707-.702.774-1.83.157-2.654L9.374 2.86C8.61 1.84 7.135 1.705 6.26 2.575l-1.57 1.56c-.433.432-.723.99-.688 1.61c.09 1.587.808 5 4.812 8.982c4.247 4.222 8.232 4.39 9.861 4.238c.516-.048.964-.31 1.325-.67l1.42-1.412c.96-.953.69-2.588-.538-3.255l-1.91-1.039c-.806-.437-1.787-.309-2.417.317"/>
                                     </svg>
                                     <span>0212 324 00 74</span>
                                 </div>
                             </a>
                         </div>
                         <div className={"desktop"}>
                             <span className={"fs-3"} style={{
                                 fontWeight: '100'
                             }}>|</span>
                         </div>
                         <div className={"d-flex pt-1 align-items-center"}>
                             <a href={'mailto:'+translations.email404 ?? 'İLETİSİM@BWA.COM.TR'}>
                                 <div className={"email d-flex align-items-start gap-3 "}>
                                     <svg xmlns="http://www.w3.org/2000/svg" width="1.45em" height="1.45em"
                                          viewBox="0 0 16 16">
                                         <path fill="currentColor" fill-rule="evenodd"
                                               d="M2 3.5h12a.5.5 0 0 1 .5.5v.572L8 8.286L1.5 4.572V4a.5.5 0 0 1 .5-.5m-.5 2.8V12a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5V6.3L8.372 9.8L8 10.014L7.628 9.8zM0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2z"
                                               clip-rule="evenodd"/>
                                     </svg>
                                     <span>{translations.email404 ?? 'İLETİSİM@BWA.COM.TR'}</span>
                                 </div>
                             </a>
                         </div>
                     </div>

                 </div>
                 <div className={'col-sm-7 order-0 order-sm-1'} style={{
                     background: 'url(/uploads/404-world.png) no-repeat top center',
                     backgroundSize: 'contain',
                     height: '500px',
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                     textAlign: 'center'
                 }}>
                     <img src={'/uploads/404-illustration.png'} className={"img-fluid"} alt=""/>
                 </div>
             </div>
            </div>
        </AppContext.Provider>
    ) : <Spinner/>;
}

ReactDOM.render(
    <Error404/>,
    document.getElementById('root')
);
